import React from "react"

const ImgIcon = ({ fill, width }) => {
  return (
    <svg
      width={width}
      // height="46px"
      viewBox="0 0 58 46"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g
        id="Page-1"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g id="picture" fill={fill} fillRule="nonzero">
          <path
            d="M57,0 L1,0 C0.448,0 0,0.447 0,1 L0,45 C0,45.553 0.448,46 1,46 L57,46 C57.552,46 58,45.553 58,45 L58,1 C58,0.447 57.552,0 57,0 Z M56,44 L2,44 L2,2 L56,2 L56,44 Z"
            id="Shape"
          ></path>
          <path
            d="M16,22.138 C19.071,22.138 21.569,19.64 21.569,16.57 C21.569,13.498 19.071,11 16,11 C12.929,11 10.431,13.498 10.431,16.569 C10.431,19.64 12.929,22.138 16,22.138 Z M16,13 C17.968,13 19.569,14.602 19.569,16.569 C19.569,18.536 17.968,20.138 16,20.138 C14.032,20.138 12.431,18.537 12.431,16.57 C12.431,14.603 14.032,13 16,13 Z"
            id="Shape"
          ></path>
          <path
            d="M7,40 C7.234,40 7.47,39.918 7.66,39.751 L23.973,25.389 L34.275,35.69 C34.666,36.081 35.298,36.081 35.689,35.69 C36.08,35.299 36.08,34.667 35.689,34.276 L30.882,29.469 L40.063,19.415 L51.324,29.738 C51.731,30.111 52.364,30.083 52.737,29.676 C53.11,29.269 53.083,28.636 52.675,28.263 L40.675,17.263 C40.479,17.084 40.218,16.995 39.955,17.001 C39.69,17.013 39.44,17.13 39.261,17.326 L29.467,28.053 L24.724,23.31 C24.35,22.937 23.752,22.918 23.356,23.266 L6.339,38.249 C5.924,38.614 5.884,39.246 6.249,39.661 C6.447,39.886 6.723,40 7,40 Z"
            id="Path"
          ></path>
        </g>
      </g>
    </svg>
  )
}

export default ImgIcon
